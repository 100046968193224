var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMsg},on:{"errorMessageClosed":function($event){_vm.errorMsg = ''}}},[(!_vm.resourceNotFound)?_c('div',[(_vm.editing && _vm.editing.status === 'draft')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}]},[_c('div',{staticClass:"flex justify-between mt-4"},[_c('TextField',{ref:"field_name",attrs:{"label":"Import Name","placeholder":'Import Name',"value":_vm.editing.name,"disabled":!_vm.$isCurrentUserAdmin,"validations":[
            {
              method: 'minLength',
              value: 2,
              error: 'Minimum 2 characters!'
            },
            {
              method: 'maxLength',
              value: 50,
              error: 'Maximum 50 characters'
            }
          ],"required":true},on:{"input":function($event){return _vm.editImportField({ key: 'name', value: $event })}}}),_c('div',[(
              _vm.hasMatchedAllRequiredFields &&
                !Object.keys(this.updatedFields).length
            )?_c('IAButton',{staticStyle:{},attrs:{"click":_vm.completeImportData,"buttonStyle":"plain","title":'Complete Import',"disabled":!_vm.$isCurrentUserAdmin}}):_vm._e(),(Object.keys(this.updatedFields).length)?_c('IAButton',{staticStyle:{},attrs:{"click":_vm.updateImportData,"buttonStyle":"plain","title":'Save Import',"disabled":!_vm.$isCurrentUserAdmin}}):_vm._e()],1)],1),(_vm.noUnmatchedColumns)?_c('div',{staticClass:"flex flex-col content-center justify-center align-center"},[_c('p',{staticClass:"text-xl"},[_vm._v("All columns have been matched successfully")]),_c('p',{staticClass:"text-xl"},[_vm._v(" Please click 'complete' button above to process your import ")])]):_c('UploadFieldsMapper',{attrs:{"data":_vm.editing.importHeadersMetadata,"options":_vm.propertyFieldOptions,"disabledOptions":_vm.selectedProperties},on:{"matchedPropertyChanged":_vm.matchedPropertyChangedHandler}})],1):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}]},[(_vm.editing && _vm.editing.status === 'complete')?_c('div',[_c('ImportReport')],1):(_vm.editing)?_c('div',{staticClass:"flex flex-col content-center justify-center align-center"},[_c('p',{staticClass:"text-xl"},[_vm._v("Your import is being processed")]),_c('p',{staticClass:"text-xl"},[_vm._v(" The result of your request will be displayed when it becomes available ")])]):_vm._e()])]):_c('ResourceNotFound',{attrs:{"title":_vm.resourceNotFoundMsg,"actionButtonTitle":"Back to imports","actionRedirectPath":"/admin/imports"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }